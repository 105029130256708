html, body, #app {
  height: 100%;
}
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

::-webkit-input-placeholder  {
  color: #bbbbbb !important;
}

::-ms-input-placeholder  {
  color: #bbbbbb !important;
}

::placeholder  {
  color: #bbbbbb !important;
}